import { Box, Flex } from '@chakra-ui/react';
import { SkipNavContent, SkipNavLink } from '@chakra-ui/skip-nav';
import styled from '@emotion/styled';
import { User, withAuthInfo } from '@propelauth/react';
import { ErrorBoundary, ErrorFallback } from 'components/error-fallback';
import IntercomWidget from 'components/intercom';
import { OnboardingStepper } from 'components/onboarding-stepper';
import { Sidebar } from 'components/Sidebar';
import { APP_ROUTES } from 'constants/app-constants';
import { BLANK_ADDRESSES } from 'constants/AppRoutes';
import { ContentComponent, OnboardingStatus, onboardingSteps, TourProvider } from 'hooks/useOnboarding';
import { useOrg } from 'hooks/useOrg';
import usePageTracking from 'hooks/usePageTracking';
import useUpdateDocTitle from 'hooks/useUpdateDocTitle';
import { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router-dom';

import AppDrawers from './app-drawers';
import AppHeader from './app-header';
import { AppOnboarding } from './app-onboarding';
import AppPopups from './app-popups';

const App = styled(Box)(() => ({ display: 'flex', height: '100vh' }));
const AppBody = styled(Box)(() => ({ height: '100%', overflow: 'auto' }));
const AppContent = styled(Box)(() => ({ overflow: 'auto', paddingBottom: '0.5rem' }));

const fallBack = (props: any) => <ErrorFallback {...props} />;

export const AppLayoutMd = withAuthInfo((props: any) => {
  const user: User | null = props.user;
  const userMetaData: any = user?.properties?.metadata || {};
  const scrollContainer: any = useRef(null);
  const location = useLocation();
  usePageTracking();
  useUpdateDocTitle(APP_ROUTES);
  const { orgId, isTest } = useOrg();

  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  useHotkeys('[', () => setIsExpanded(!isExpanded), [isExpanded]);

  const skipHeaderRoute = ['/invalid-addresses', BLANK_ADDRESSES];
  const showHeader = !skipHeaderRoute.includes(location.pathname) && location.pathname !== '/products';

  useEffect(() => {
    if (scrollContainer?.current) {
      scrollContainer.current.scrollTop = 0;
    }
  }, [location]);

  const sidebarWidth = isExpanded ? '250px' : '4rem';
  // not to render app content until org selected
  if (!orgId) {
    return (
      <App>
        <ErrorBoundary fallback={fallBack}>
          <Box h={'100vh'} w={sidebarWidth}>
            <Sidebar isExpanded={isExpanded} onSidebarExpand={() => setIsExpanded(!isExpanded)} />
          </Box>
          <AppBody p={4} w="100%"></AppBody>
          <Box position="fixed" bottom="10px" right="10px" padding="10px"></Box>
        </ErrorBoundary>
      </App>
    );
  }

  return (
    <>
      <SkipNavLink zIndex={9999}>Skip to content</SkipNavLink>
      <App>
        <TourProvider
          onClickMask={() => {
            // to override default mask click
          }}
          steps={onboardingSteps}
          defaultOpen={
            userMetaData.onboarding_status === undefined ||
            userMetaData.onboarding_status === OnboardingStatus.NOT_STARTED
          }
          showDots={false}
          showBadge={false}
          disableInteraction={true}
          disableKeyboardNavigation={true}
          disableFocusLock={true}
          ContentComponent={ContentComponent}
          styles={{ popover: base => ({ ...base, padding: '1rem', borderRadius: '4px' }) }}
        >
          <AppOnboarding status={userMetaData.onboarding_status} />
          <ErrorBoundary fallback={fallBack}>
            <Box h={'100vh'} w={sidebarWidth}>
              <Sidebar isExpanded={isExpanded} onSidebarExpand={() => setIsExpanded(!isExpanded)} />
            </Box>
            <AppBody px={6} w="100%" borderTop={isTest ? '7px solid #DD6B20' : 'unset'}>
              <SkipNavContent />
              {showHeader && <AppHeader />}
              <AppContent ref={scrollContainer} height={showHeader ? 'calc(100% - 4rem)' : '100%'}>
                {props.children}
              </AppContent>
            </AppBody>
            <AppPopups />
            <AppDrawers />

            <Flex zIndex={1000} position="fixed" bottom="10px" right="80px" padding="10px" gap={4} align={'center'}>
              <OnboardingStepper />
            </Flex>
            <Flex
              zIndex={1000}
              flexDir={'column'}
              position="fixed"
              bottom="10px"
              right="16px"
              padding="10px"
              gap={4}
              align={'center'}
            >
              <IntercomWidget />
            </Flex>
          </ErrorBoundary>
        </TourProvider>
      </App>
    </>
  );
});

export default AppLayoutMd;
