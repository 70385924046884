import { HStack, IconButton, Tooltip } from '@chakra-ui/react';
import { KSheetIcon } from 'components/icons';
import ExportTransactions from 'pages/Transactions/components/export-transaction';
import { TransactionPopover } from 'pages/Transactions/components/transaction-filter/transaction-popover';
import TransactionSearch from 'pages/Transactions/components/TransactionSearch';

const KINTSUGI_SHEET_URL = 'https://workspace.google.com/marketplace/app/kintsugi_sheets/160068196398';

export const TransactionTrackingToolbar = () => {
  return (
    <HStack>
      <TransactionSearch />
      <Tooltip placement="bottom-end" shouldWrapChildren label={'Kintsugi Sheets'}>
        <IconButton
          w={6}
          h={6}
          aria-label={'Kintsugi Sheets'}
          mr={1}
          variant={'transparent-with-icon'}
          onClick={() => window.open(KINTSUGI_SHEET_URL, '_blank')}
        >
          <KSheetIcon />
        </IconButton>
      </Tooltip>
      <TransactionPopover />
      <ExportTransactions />
    </HStack>
  );
};
