import { useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getDashboardOnboardingStepStatus, ONBOARDING_STATE_KEY } from 'apis/dashboard-apis';
import { usePaywall } from 'app/acl/paywall';
import Button from 'component-library/components/ButtonTmp/button';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { OnBoardingStepStatus } from 'types/onboarding';

import { OrgDetailPrompt } from './orgdetail-prompt';
import { RegistrationForm } from './registration-form';

export const CreateRegistration = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { orgId, isTest } = useOrg();
  const { isPaidUser } = usePaywall();
  const [, setSearchParams] = useQueryParam('');

  const { data, isPending } = useQuery({
    queryKey: [ONBOARDING_STATE_KEY, 'steps', 'status', orgId],
    queryFn: async () => {
      const response = await getDashboardOnboardingStepStatus(orgId);
      return response.data as OnBoardingStepStatus;
    },
  });

  const handleClick = () => {
    if (isPaidUser || isTest) {
      onOpen();
    } else {
      setSearchParams({ openPricingModal: 'true' });
    }
  };

  return (
    <>
      <Button isLoading={isPending} variant={'solid'} color={'primary'} width={'100px'} onClick={handleClick}>
        Import
      </Button>

      {!isPending &&
        (data?.onboarding_steps_status || isTest ? (
          <RegistrationForm isOpen={isOpen} onClose={onClose} />
        ) : (
          <OrgDetailPrompt isOpen={isOpen} onClose={onClose} data={data} />
        ))}
    </>
  );
};
