import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  CheckboxGroup,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FILINGS_STATE_KEY, getFilings } from 'apis/filing-apis';
import Button from 'component-library/components/ButtonTmp/button';
import DatePicker from 'component-library/components/date-picker/date-picker';
import Select from 'component-library/components/Select/Select';
import { USStates } from 'constants/app-constants';
import { addressStatusOptions, statusOptions, transactionTypeOptions } from 'constants/transactions';
import useAsyncActionLoader from 'hooks/useAsyncActionLoader';
import { useOrg } from 'hooks/useOrg';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchRegistrations } from 'redux/slices/registrations';
import { storeFilter } from 'redux/slices/transactions';
import { RootState, useAppDispatch } from 'redux/store';
import { FilingInstance } from 'types/shared-types';
import { toDateShort } from 'utils/dates';

type TransactionFilterProps = {
  onClose: () => void;
  filters: {
    address_status__in: string;
    status: string;
    state_code: string;
    country: string;
    filing_id: string;
    transaction_type: string;
    date__gte: string;
    date__lte: string;
  };
};

export const TransactionFilter = ({ onClose, filters }: TransactionFilterProps) => {
  const registrations = useSelector(({ registrations }: RootState) => registrations.entities);
  const dispatch = useAppDispatch();
  const { orgId } = useOrg();
  const { state, pathname } = useLocation();
  const [accordionIndex, setAccordionIndex] = useState<number | number[]>([]);
  const [startDate, setStartDate] = useState<string>(filters?.date__gte || '');
  const [endDate, setEndDate] = useState<string>(filters?.date__lte || '');
  const [selectedAddressStatus, setSelectedAddressStatus] = useState<string>(filters?.address_status__in || '');
  const [selectedTransactionType, setSelectedTransactionType] = useState<string>(filters?.transaction_type || '');
  const [selectedState, setSelectedState] = useState<string>(filters?.state_code || '');
  const [selectedStatus, setSelectedStatus] = useState<string>(filters?.status || '');
  const [selectedCountry, setSelectedCountry] = useState<string>(filters?.country || 'US');
  const [selectedFiling, setSelectedFiling] = useState<string>(filters?.filing_id || '');
  const { executeAction } = useAsyncActionLoader(dispatch);

  const replaceState = () => {
    window.history.replaceState({}, '', pathname);
  };

  useEffect(() => {
    if (isEmpty(registrations)) {
      executeAction(fetchRegistrations({ orgId, page: 1, size: 100 }));
    }
    if (!isEmpty(state) && 'fillingId' in state) {
      dispatch(
        storeFilter({
          status: null,
          country: selectedCountry,
          filing_id: selectedFiling,
        })
      );
    }
    return () => {
      replaceState();
    };
  }, []);

  const { data: filingOptions } = useQuery({
    queryKey: [FILINGS_STATE_KEY, orgId, 1, 100],
    queryFn: async () => {
      const { data } = await getFilings({ orgId, params: { page: 1, size: 100 } });
      return data.items;
    },
    select: data => {
      return data.map(({ start_date, end_date, registration_id, id }: FilingInstance) => {
        const stateName = registrations[registration_id]?.state_name ?? '';
        const date = `${toDateShort(start_date)} - ${toDateShort(end_date)}`;
        return {
          value: id,
          label: stateName ? `${stateName} (${date})` : date,
        };
      });
    },
    refetchOnWindowFocus: false,
  });

  const handleSave = () => {
    dispatch(
      storeFilter({
        address_status__in: selectedAddressStatus || null,
        status: selectedStatus || null,
        state_code: selectedState || null,
        country: selectedCountry || null,
        filing_id: selectedFiling || null,
        transaction_type: selectedTransactionType || null,
        date__gte: startDate || null,
        date__lte: endDate || null,
      })
    );
    replaceState();
    onClose();
  };

  const handleClear = () => {
    setSelectedState('');
    dispatch(storeFilter({}));
    onClose();
  };

  useEffect(() => {
    const newIndexes = [];
    if (filters?.country) newIndexes.push(0);
    if (filters?.state_code) newIndexes.push(1);
    if (filters?.transaction_type) newIndexes.push(2);
    if (filters?.address_status__in) newIndexes.push(3);
    if (filters?.filing_id) newIndexes.push(4);
    if (filters?.status) newIndexes.push(5);
    if (filters?.date__gte || filters?.date__lte) newIndexes.push(6);

    setAccordionIndex(newIndexes);
  }, []);

  return (
    <Stack overflow={'auto'} color={'gray.900'}>
      <HStack position={'sticky'} top={0} bgColor={'#fff'} zIndex={1} justifyContent={'space-between'}>
        <Text fontSize={'18px'} fontWeight={500}>
          Filters
        </Text>
        <Text fontSize={'12px'} color={'#4285F4'} cursor={'pointer'} onClick={handleClear}>
          Clear All Filters
        </Text>
      </HStack>
      <Stack>
        <Accordion allowMultiple variant={'filter'} index={accordionIndex} onChange={index => setAccordionIndex(index)}>
          <AccordionItem>
            <AccordionButton>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Country
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel gap={2}>
              <Select value={selectedCountry} onChange={e => setSelectedCountry(e.target.value)}>
                <option value="US">US</option>
              </Select>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                State
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel gap={2}>
              <Select value={selectedState} onChange={e => setSelectedState(e.target.value)}>
                <>
                  <option value={''}>Select State</option>
                  {USStates.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </>
              </Select>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Transaction Type
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel gap={2}>
              <CheckboxGroup
                value={selectedTransactionType.split(',').filter(Boolean)}
                onChange={values => setSelectedTransactionType(values.join(','))}
              >
                {transactionTypeOptions.map(({ value, title }) => (
                  <Checkbox variant={'filter'} key={value} value={value}>
                    {title}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Address Status
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel gap={2}>
              <CheckboxGroup
                value={selectedAddressStatus.split(',').filter(Boolean)}
                onChange={values => setSelectedAddressStatus(values.join(','))}
              >
                {addressStatusOptions.map(({ value, title }) => (
                  <Checkbox variant={'filter'} key={value} value={value}>
                    {title}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Filing
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel gap={2}>
              <Select value={selectedFiling} onChange={e => setSelectedFiling(e.target.value)}>
                <>
                  <option value={''}>Select Filing</option>
                  {filingOptions?.map(({ value, label }: any) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </>
              </Select>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Status
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Select value={selectedStatus} onChange={e => setSelectedStatus(e.target.value)}>
                <>
                  <option value={''}>Select Status</option>
                  {statusOptions.map(({ value, title }) => (
                    <option key={value} value={value}>
                      {title}
                    </option>
                  ))}
                </>
              </Select>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Date
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel gap={4}>
              <Box>
                <Text fontWeight={'medium'} fontSize={'sm'} mb={1}>
                  Start Date
                </Text>
                <DatePicker
                  selected={startDate}
                  onChange={date => {
                    setStartDate(date);
                  }}
                />
              </Box>
              <Box>
                <Text fontWeight={'medium'} fontSize={'sm'} mb={1}>
                  End Date
                </Text>
                <DatePicker
                  selected={endDate}
                  onChange={date => {
                    setEndDate(date);
                  }}
                />
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <HStack direction="row" justifyContent={'space-around'} spacing="18px">
          <Button variant="outline" color="secondary" onClick={onClose} width={'132px'} height={'32px'}>
            Cancel
          </Button>
          <Button variant="solid" color="primary" onClick={handleSave} width={'132px'} height={'32px'}>
            Apply
          </Button>
        </HStack>
      </Stack>
    </Stack>
  );
};
