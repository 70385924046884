import { Checkbox, Flex, FormControl, IconButton, Radio, RadioGroup, Stack, useDisclosure } from '@chakra-ui/react';
import { exportTransactionsReport } from 'apis/transaction-api';
import Button from 'component-library/components/ButtonTmp/button';
import FormLabel from 'component-library/components/FormLabel';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import Select from 'component-library/components/Select';
import Text from 'component-library/components/Text';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { ExcelIcon } from 'components/icons';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import useAsyncActionLoader from 'hooks/useAsyncActionLoader';
import { useOrg } from 'hooks/useOrg';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

interface FormValues {
  country_code: string;
  start_year: number;
  end_year: number;
  include_invalid_addresses?: boolean;
}

const currentYear = new Date().getFullYear();
const last8Years = Array.from({ length: 8 }, (_, i) => currentYear - i);

const validationSchema = Yup.object().shape({
  country_code: Yup.string().required('Country is required'),
  start_year: Yup.number()
    .min(currentYear - 8, `Start Year must be greater than ${currentYear - 8}`)
    .max(currentYear, `Start Year must be less than ${currentYear}`)
    .required('Start Year is required'),
  end_year: Yup.number()
    .min(currentYear - 8, `End Year must be greater than ${currentYear - 8}`)
    .max(currentYear, `End Year must be less than ${currentYear}`)
    .required('End Year is required'),
});

const ExportTransactions = () => {
  const { orgId } = useOrg();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const { loading, executeAction } = useAsyncActionLoader(exportTransactionsReport);
  const [reportType, setReportType] = useState('transactionSummary');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const options = last8Years.map(year => ({
    value: String(year),
    label: String(year),
  }));
  const formik = useFormik<FormValues>({
    initialValues: {
      country_code: 'US',
      start_year: currentYear - 1,
      end_year: currentYear,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      try {
        await executeAction(orgId, reportType, values);
        onClose();
        handleSuccessNotification('Transaction Report Export Started. Check your email for a download link.');
      } catch (e) {
        handleFailNotification(e);
        onClose();
      }
    },
  });

  const handleInvalidAddresses = (e: { target: { checked: any } }) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      formik.setFieldValue('include_invalid_addresses', true);
    } else {
      const newValues = { ...formik.values };
      delete newValues.include_invalid_addresses;
      formik.setValues(newValues);
    }
  };

  useEffect(
    () => () => {
      formik.resetForm();
    },
    []
  );

  return (
    <>
      <Tooltip placement="bottom-end" shouldWrapChildren label={'Export Excel'}>
        <IconButton
          isLoading={loading}
          aria-label={'Export Transaction'}
          mr={1}
          variant={'transparent-with-icon'}
          onClick={onOpen}
        >
          <ExcelIcon />
        </IconButton>
      </Tooltip>
      {isOpen && (
        <ModalPopup
          closeOnOverlayClick={false}
          size={'lg'}
          isOpen={isOpen}
          onClose={onClose}
          header="Send Report"
          footer={
            <Flex gap={4}>
              <Button variant={'outline'} color={'secondary'} onClick={onClose}>
                Cancel
              </Button>
              <Button
                isLoading={loading}
                variant={'solid'}
                color={'primary'}
                width={'90px'}
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </Button>
            </Flex>
          }
        >
          <Flex direction="column" gap={3}>
            <Text>
              The report will be sent to your email address and will arrive in the next 1-2 minutes depending on how
              much data is in your organization.
            </Text>
            <Text>Select report option:</Text>
            <RadioGroup defaultValue="transactionSummary" value={reportType} onChange={setReportType}>
              <Stack>
                <Radio value="transactionDetails">
                  <Text>Transaction Details</Text>
                </Radio>
                <Radio value="transactionSummary">
                  <Text>Transaction Summary</Text>
                </Radio>
              </Stack>
            </RadioGroup>
            <Flex gap={2}>
              <FormControl>
                <FormLabel>Country</FormLabel>
                <Select
                  value={formik.values.country_code}
                  onChange={formik.handleChange}
                  options={[{ value: 'US', label: 'US' }]}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="start_year">Start Year</FormLabel>
                <Select
                  id="start_year"
                  name="start_year"
                  value={formik.values.start_year}
                  onChange={formik.handleChange}
                  options={options}
                />
                {formik.errors.start_year && formik.touched.start_year && (
                  <Text color={'#E53E3E'}>{formik.errors.start_year}</Text>
                )}
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="end_year">End Year</FormLabel>
                <Select
                  id="end_year"
                  name="end_year"
                  value={formik.values.end_year}
                  onChange={formik.handleChange}
                  options={options}
                />
                {formik.errors.end_year && formik.touched.end_year && (
                  <Text color={'#E53E3E'}>{formik.errors.end_year}</Text>
                )}
              </FormControl>
            </Flex>
            <Checkbox
              isDisabled={reportType == 'transactionSummary'}
              isChecked={!!formik.values.include_invalid_addresses}
              onChange={handleInvalidAddresses}
            >
              {' '}
              Include transactions with invalid addresses{' '}
            </Checkbox>
          </Flex>
        </ModalPopup>
      )}
    </>
  );
};

export default ExportTransactions;
