/* eslint-disable react-hooks/rules-of-hooks */
import { useAuthInfo } from '@propelauth/react';
import * as Sentry from '@sentry/react';
import useTracking from 'hooks/useTracking';
import { CustomerDetails } from 'pages/Customers/components/customer-details';
import BlankAddressTransactions from 'pages/Dashboard/components/BlankAddressTransactions';
import Integrations from 'pages/Import/components/integrations';
import Presence from 'pages/Presence';
import { TransactionDetails } from 'pages/Transactions/components/transaction-details';
import React, { lazy, useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { BLANK_ADDRESSES, TOOLS_AND_GUIDE } from '../constants/AppRoutes';
import { AppLayout } from './app-layout';

const Dashboard = lazy(() => import('pages/Dashboard'));
const Settings = lazy(() => import('pages/Settings'));
const Customers = lazy(() => import('pages/Customers'));
const Transactions = lazy(() => import('pages/Transactions/transaction'));
const Registrations = lazy(() => import('pages/Registrations'));
const Nexus = lazy(() => import('pages/Nexus'));
const Filing = lazy(() => import('pages/Filing'));
const ShopifyLanding = lazy(() => import('shopify'));
const ConnectShopify = lazy(() => import('shopify/ConnectShopify'));
const ToolsAndGuide = lazy(() => import('pages/ToolsAndGuide'));
const Products = lazy(() => import('pages/Products'));
const Import = lazy(() => import('pages/Import'));
const Exemptions = lazy(() => import('pages/Exemptions'));
const BulkCleanupAddresses = lazy(() => import('pages/Addresses'));
const TaxDetails = lazy(() => import('pages/TaxDetails/tax-details'));
const AppRedirector = lazy(() => import('app/app-redirector'));
const Supports = lazy(() => import('pages/Supports'));

const AppRoutes = () => {
  const { identifyUser } = useTracking();
  const { user } = useAuthInfo();

  useEffect(() => {
    if (user) {
      identifyUser({ email: user.email, id: user.userId });
      Sentry.setUser({ email: user.email, id: user.userId });
    }
  }, [user?.userId]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="/configurations/:tab?" element={<Settings />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/:customerId" element={<CustomerDetails />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/transactions/:id" element={<TransactionDetails />} />
          <Route path="/nexus" element={<Nexus />} />
          <Route path="/filing" element={<Filing />} />
          <Route path="/registrations" element={<Registrations />} />
          <Route path="/source" element={<Import />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/shopify" element={<ShopifyLanding />} />
          <Route path="/connect/shopify" element={<ConnectShopify />} />

          <Route path={TOOLS_AND_GUIDE} element={<ToolsAndGuide />} />
          <Route path="/products" element={<Products />} />
          <Route path="/exemptions" element={<Exemptions />} />
          <Route path="/presence" element={<Presence />} />
          <Route path="/invalid-addresses" element={<BulkCleanupAddresses />} />
          <Route path={BLANK_ADDRESSES} element={<BlankAddressTransactions />} />
          <Route path="/taxdetails" element={<TaxDetails />} />
        </Route>
        <Route path="/supports" element={<Supports />} />
        <Route path="/redirect" element={<AppRedirector />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
