import { Box } from '@chakra-ui/react';
import { SkipNavContent, SkipNavLink } from '@chakra-ui/skip-nav';
import styled from '@emotion/styled';
import { ErrorBoundary, ErrorFallback } from 'components/error-fallback';
import { OnboardingStepper } from 'components/onboarding-stepper';
import { MobileSubNav } from 'components/Sidebar/sidebar.sm';
import { APP_ROUTES } from 'constants/app-constants';
import { useOrg } from 'hooks/useOrg';
import usePageTracking from 'hooks/usePageTracking';
import useUpdateDocTitle from 'hooks/useUpdateDocTitle';
import React from 'react';

import AppHeaderSm from './app-header.sm';

const App = styled(Box)(() => ({ height: '100vh' }));
const AppBody = styled(Box)(() => ({ height: '100%', overflow: 'auto' }));
const AppContent = styled(Box)(() => ({ height: 'calc(100% - 3rem)', overflow: 'auto' }));

const fallBack = (props: any) => <ErrorFallback {...props} />;

export const AppLayoutSm = (props: { children: React.ReactNode }) => {
  usePageTracking();
  useUpdateDocTitle(APP_ROUTES);
  const { orgId } = useOrg();

  // not to render app content until org selected to avoid edge cases
  if (!orgId) {
    return (
      <>
        <SkipNavLink zIndex={9999}>Skip to content</SkipNavLink>

        <App>
          <ErrorBoundary fallback={fallBack}>
            <Box>
              <MobileSubNav initialDrawerOpen={true} />
            </Box>
            <AppBody px={4} w="100%">
              <SkipNavContent />
            </AppBody>
            <Box position="fixed" bottom="10px" right="10px" padding="10px"></Box>
          </ErrorBoundary>
        </App>
      </>
    );
  }

  return (
    <>
      <SkipNavLink zIndex={9999}>Skip to content</SkipNavLink>

      <App>
        <ErrorBoundary fallback={fallBack}>
          <Box>
            <MobileSubNav initialDrawerOpen={false} />
          </Box>
          <AppBody px={4} w="100%">
            <SkipNavContent />
            <AppHeaderSm />
            <AppContent>{props.children}</AppContent>
          </AppBody>
          <Box position="fixed" bottom="10px" right="10px" padding="10px">
            <OnboardingStepper />
          </Box>
        </ErrorBoundary>
      </App>
    </>
  );
};

export default AppLayoutSm;
