import { IconButton } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getTransactionForId } from 'apis/transaction-api';
import { TRANSACTION_STATE_KEY } from 'apis/transactions';
import { FilingIcon } from 'components/icons';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { useParams } from 'react-router-dom';

const TransactionDetailsToolbar = () => {
  const { id } = useParams<{ id: string }>();
  const [setSearchParams] = useQueryParam('showFilingDetails');
  const { orgId } = useOrg();

  const {
    isPending,
    data: transactionData,
    isError,
  } = useQuery({
    queryKey: [TRANSACTION_STATE_KEY, id],
    queryFn: async () => {
      try {
        const res = await getTransactionForId(orgId, id || '');
        return res.data || {};
      } catch (err) {
        console.log(err);
      }
    },
  });

  if (!id || isError) {
    return null;
  }

  return (
    transactionData?.filing_id && (
      <IconButton
        isLoading={isPending}
        rounded={'sm'}
        variant={'ghost'}
        aria-label="transaction-filing"
        onClick={e => {
          e.stopPropagation();
          setSearchParams({ showFilingDetails: 'true', filingId: transactionData.filing_id });
        }}
        color={'#000'}
        icon={<FilingIcon />}
      />
    )
  );
};

export default TransactionDetailsToolbar;
