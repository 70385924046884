import { OrganizationRead } from '_api-client';
import {
  ApiKeyCreateResponse,
  APIKeyRequest,
  APIKeysRequest,
  APIKeysResponse,
  InvitesResponse,
  UsersRequest,
  UsersResponse,
} from 'types/organizations';
import { AutoFileAutoRegisterUpdateRequest, UserInviteRequest, UserRoleUpdateRequest } from 'types/shared-types';

import api from './api';

export const USERS_STATE_KEY = 'users';
export const INVITES_STATE_KEY = 'invites';
export const API_KEYS_STATE = 'apiKeys';

export const getAllOrgs = () => {
  return api.get('/v1/organizations/');
};

const getAllOrgsByPaged = async ({ size }: { size: number }) => {
  let totalPages = 1;
  let results: OrganizationRead[] = [];
  let page = 1;

  while (page <= totalPages) {
    const params = {
      page: page,
      size: size,
    };
    try {
      const { data } = await api.get(`/v1/organizations/`, {
        params: {
          ...params,
        },
      });
      results = [...results, ...data.items];
      totalPages = data.pages;
      page++;
    } catch (error) {
      return results;
    }
  }
  return results;
};

const getOrgs = (orgId: string, page: number, size: number, query: string) => {
  let params = {
    page,
    size,
  } as Record<string, any>;
  if (query) {
    params = {
      ...params,
      page: 1, // override the page size for search to ensure the data is not under looked.
      query,
    };
  }

  if (orgId) {
    params = {
      ...params,
      selected_organization_id: orgId,
    };
  }
  return api.get(`/v1/organizations/`, {
    params,
  });
};

const getOrgDetails = (orgId: string) => {
  return api.get(`/v1/organization_details/`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const addOrUpdateBusinessDetails = (orgId: string, payload: any) => {
  return api.put(`/v1/organization_details/business_details/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const addOrUpdateBusinessAddress = (orgId: string, payload: any) => {
  return api.put(`/v1/organization_details/business_address/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const addOrUpdateBusinessInformation = (orgId: string, payload: any) => {
  return api.put(`/v1/organization_details/business_information/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const addOrUpdateBusinessContact = (orgId: string, payload: any) => {
  return api.put(`/v1/organization_details/business_contact/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const addOrUpdateBusinessOwners = (orgId: string, payload: any) => {
  return api.put(`/v1/organization_details/business_owners/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const archiveOrg = (orgId: string, orgName: string) => {
  return api.delete(`/v1/organizations/${orgId}/`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      organization_name: orgName,
    },
  });
};

const createTestOrganization = (orgName: string, isTest: boolean) => {
  return api.post(`/v1/organizations`, {
    name: orgName,
    is_test: isTest,
  });
};

const getAllEmailGroups = (orgId: string) => {
  return api.get(`/v1/email_groups`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const addEmailGroupMember = (orgId: string, payload: { email_group: string; member: string }) => {
  const { email_group, member } = payload;
  return api.post(`/v1/email_groups/`, null, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      email_group: email_group,
      member: member,
    },
  });
};

const deleteEmailGroupMember = (orgId: string, emailGroup: string, member: string) => {
  return api.delete(`/v1/email_groups/`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      email_group: emailGroup,
      member: member,
    },
  });
};

const getPhysicalMailAddress = (orgId: string) => {
  return api.get(`/v1/organizations/physical_mail`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getPhysicalMailExternalId = (orgId: string) => {
  return api.get(`/v1/organizations/physical_mail/external_id`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getPhysicalMailOnboardingLink = (orgId: string, payload: any) => {
  return api.post(`/v1/organizations/physical_mail/onboard`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getPhysicalMailStatus = (orgId: string, payload: any) => {
  return api.post(`/v1/organizations/physical_mail/sync`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const ORG_DETAILS_STATE_KEY = 'orgDetails';

export const PHYSICAL_MAIL = 'physicalMail';

const getUsersByOrgId = (orgId: string, { pageSize, pageNumber, includeOrgs = true, role }: UsersRequest) => {
  return api.get<UsersResponse>(`/v1/organizations/users`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      page_size: pageSize,
      page_number: pageNumber,
      include_orgs: includeOrgs,
      role,
    },
  });
};

const getInvitesByOrgId = (orgId: string, { pageSize, pageNumber }: UsersRequest) => {
  return api.get<InvitesResponse>(`/v1/organizations/users/invites`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      page_size: pageSize,
      page_number: pageNumber,
    },
  });
};

const inviteUserToOrg = (orgId: string, payload: UserInviteRequest) => {
  return api.post<{ message: string }>(`/v1/organizations/users/invites`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const updateUserRole = (orgId: string, { userId, role }: UserRoleUpdateRequest) => {
  return api.put<{ message: string }>(
    `/v1/organizations/users/${userId}/role`,
    { role },
    {
      headers: {
        'x-organization-id': orgId,
      },
    }
  );
};

const removeUserFromOrg = (orgId: string, userId: string) => {
  return api.delete<{ message: string }>(`/v1/organizations/users/${userId}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getAutoFileAutoRegister = (orgId: string) => {
  return api.get(`/v1/organization_details/auto_file/auto_register`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const putAutoFileAutoRegister = (orgId: string, payload: AutoFileAutoRegisterUpdateRequest) => {
  return api.put<{ message: string }>(`/v1/organization_details/auto_file/auto_register`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getAPIKeysByOrgId = (orgId: string, { active = true, pageSize, pageNumber }: APIKeysRequest) => {
  return api.get<APIKeysResponse>(`/v1/organizations/api_keys`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      page_size: pageSize,
      page_number: pageNumber,
      active,
    },
  });
};

const createAPIKey = (orgId: string, payload: APIKeyRequest) => {
  return api.post<ApiKeyCreateResponse>(`/v1/organizations/api_keys`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const updateAPIKey = (orgId: string, apiKeyId: string, payload: APIKeyRequest) => {
  return api.put(`/v1/organizations/api_keys/${apiKeyId}`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const deleteAPIKey = (orgId: string, apiKeyId: string) => {
  return api.delete(`/v1/organizations/api_keys/${apiKeyId}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

export {
  addEmailGroupMember,
  addOrUpdateBusinessAddress,
  addOrUpdateBusinessContact,
  addOrUpdateBusinessDetails,
  addOrUpdateBusinessInformation,
  addOrUpdateBusinessOwners,
  archiveOrg,
  createAPIKey,
  createTestOrganization,
  deleteAPIKey,
  deleteEmailGroupMember,
  getAllEmailGroups,
  getAllOrgsByPaged,
  getAPIKeysByOrgId,
  getAutoFileAutoRegister,
  getInvitesByOrgId,
  getOrgDetails,
  getOrgs,
  getPhysicalMailAddress,
  getPhysicalMailExternalId,
  getPhysicalMailOnboardingLink,
  getPhysicalMailStatus,
  getUsersByOrgId,
  inviteUserToOrg,
  ORG_DETAILS_STATE_KEY,
  putAutoFileAutoRegister,
  removeUserFromOrg,
  updateAPIKey,
  updateUserRole,
};
