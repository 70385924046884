import { Flex, FormControl, HStack, IconButton, Input, Stack, Switch } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CUSTOMERS_STATE_KEY } from 'apis/customers';
import { getAttachmentById, updateExemption, uploadResellerCertificate } from 'apis/exemption-apis';
import { EXEMPTIONS_STATE_KEY } from 'apis/exemptions';
import Button from 'component-library/components/ButtonTmp/button';
import DatePicker from 'component-library/components/date-picker/date-picker';
import FormLabel from 'component-library/components/FormLabel';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import Text from 'component-library/components/Text';
import useToast from 'component-library/hooks/useToast';
import AlertBanner from 'components/alert/alert';
import { RemoveIcon } from 'components/icons';
import { USStatesByCode } from 'constants/app-constants';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';
import { getHumanReadableString } from 'utils/enum-helpers';
import { date, object, ref, string } from 'yup';

const validationSchema = object().shape({
  start_date: date().required('Start date is required'),
  end_date: date().min(ref('start_date'), "End date can't be before start date").required('End date is required'),
  jurisdiction: string().required('Jurisdiction is required'),
  FEIN: string().required('FEIN is required'),
  sales_tax_id: string().required('Sales Tax Id is required'),
});

type EditExemptionsFormProps = {
  isOpen: boolean;
  onClose: () => void;
  exemption: any;
};

export const EditExemptionsForm = ({ isOpen, onClose, exemption }: EditExemptionsFormProps) => {
  const id = String(exemption.id);
  const { orgId } = useOrg();
  const toast = useToast();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const queryClient = useQueryClient();
  const [fileName, setFileName] = useState('');
  const [dateChanged, setDateChanged] = useState(false);

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [CUSTOMERS_STATE_KEY, orgId] });
    queryClient.invalidateQueries({ queryKey: [EXEMPTIONS_STATE_KEY, orgId] });
  };

  const mutation = useMutation({
    mutationFn: (payload: any) => {
      return updateExemption(orgId, id, payload);
    },
    onSuccess: () => {
      const file = formik.values.certificate_location;
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        uploadResellerCertificate(exemption.id, orgId, formData)
          .then(() => {
            invalidateQuery();
            handleSuccessNotification('Exemption updated successfully');
          })
          .catch(error => {
            handleFailNotification(error);
          });
      } else {
        invalidateQuery();
        handleSuccessNotification('Exemption updated successfully');
      }
      onClose();
    },
  });

  useQuery({
    queryKey: ['getAttachmentById', orgId, exemption.attachment_id],
    queryFn: async () => {
      if (exemption.attachment_id) {
        const res = await getAttachmentById(orgId, exemption.attachment_id);
        setFileName(res.data.file_name);
        return res.data;
      }
    },
    enabled: !!exemption.attachment_id,
  });

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    handleFileSelection(droppedFile);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleFileSelection = (selectedFile: File) => {
    if (selectedFile.type !== 'application/pdf') {
      toast({
        status: 'error',
        title: 'Invalid file type',
        description: 'Only PDF file is allowed.',
      });
      return;
    }
    setFileName(selectedFile.name);
    formik.setFieldValue('certificate_location', selectedFile);
  };

  const formik = useFormik({
    initialValues: {
      exemption_type: exemption.exemption_type,
      customer_id: exemption.customer_id,
      start_date: getHumanReadableString(exemption.start_date),
      end_date: getHumanReadableString(exemption.end_date),
      FEIN: getHumanReadableString(exemption.FEIN),
      sales_tax_id: getHumanReadableString(exemption.sales_tax_id),
      jurisdiction: exemption.jurisdiction,
      certificate_location: '',
      reseller: exemption.reseller,
      status: 'ACTIVE',
      transaction_id: exemption.transaction_id,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      mutation.mutate(values);
    },
  });

  return (
    <ModalPopup
      isCentered
      size={'lg'}
      isOpen={isOpen}
      onClose={onClose}
      header="Edit Exemption"
      footer={
        <Flex gap={2}>
          <Button variant={'outline'} color={'secondary'} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={'solid'}
            color={'primary'}
            width={'90px'}
            onClick={() => formik.handleSubmit()}
            isLoading={mutation.isPending}
          >
            Save
          </Button>
        </Flex>
      }
    >
      <form noValidate>
        <Stack gap={'16px'}>
          <HStack>
            <FormControl isRequired>
              <FormLabel htmlFor="jurisdiction">Country</FormLabel>
              <Input id="country" name="country" value="US" isDisabled={true}></Input>
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="jurisdiction">Jurisdiction</FormLabel>
              <Input
                id="jurisdiction"
                name="jurisdiction"
                value={USStatesByCode[exemption.jurisdiction].label}
                isDisabled={true}
              ></Input>
            </FormControl>
          </HStack>
          <HStack align={'baseline'}>
            <FormControl isRequired>
              <FormLabel htmlFor="start_date">Start Date</FormLabel>
              <DatePicker
                selected={formik.values.start_date}
                onChange={date => {
                  formik.setFieldValue('start_date', date);
                  setDateChanged(true);
                }}
              />
              {formik.errors.start_date && formik.touched.start_date && (
                <Text color={'#E53E3E'}>{formik.errors.start_date}</Text>
              )}
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="end_date">End Date</FormLabel>
              <DatePicker
                selected={formik.values.end_date}
                onChange={date => {
                  formik.setFieldValue('end_date', date);
                  setDateChanged(true);
                }}
              />
              {formik.errors.end_date && formik.touched.end_date && (
                <Text color={'#E53E3E'}>{formik.errors.end_date}</Text>
              )}
            </FormControl>
          </HStack>
          {dateChanged && (
            <AlertBanner
              message="We will not retroactively update transactions for this exemption"
              variant={'formAlertVariant'}
              padding={0}
            ></AlertBanner>
          )}
          <HStack align={'baseline'}>
            <FormControl isRequired>
              <FormLabel htmlFor="FEIN">FEIN</FormLabel>
              <Input id="FEIN" type="string" name="FEIN" value={formik.values.FEIN} onChange={formik.handleChange} />
              {formik.errors.FEIN && formik.touched.FEIN && <Text color={'#E53E3E'}>{formik.errors.FEIN}</Text>}
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="sales_tax_id">Sales Tax ID</FormLabel>
              <Input
                id="sales_tax_id"
                type="string"
                name="sales_tax_id"
                value={formik.values.sales_tax_id}
                onChange={formik.handleChange}
              />
              {formik.errors.sales_tax_id && formik.touched.sales_tax_id && (
                <Text color={'#E53E3E'}>{formik.errors.sales_tax_id}</Text>
              )}
            </FormControl>
          </HStack>
          <FormControl isRequired display="flex" alignItems="center">
            <FormLabel htmlFor="reseller" mb="0" color={'#262B47'}>
              Is this customer a reseller?
            </FormLabel>
            <Switch
              id="reseller"
              name="reseller"
              isChecked={formik.values.reseller}
              onChange={e => {
                formik.handleChange(e);
                formik.setFieldValue('exemption_type', e.target.checked ? 'wholesale' : exemption.exemption_type);
              }}
            />
          </FormControl>
          <Text>Upload Certificate</Text>
          {formik.values.certificate_location || fileName ? (
            <HStack>
              <Text>{fileName}</Text>
              <IconButton
                variant={'transparent-with-icon'}
                aria-label="Remove file"
                onClick={() => {
                  formik.setFieldValue('certificate_location', null);
                  setFileName('');
                }}
                icon={<RemoveIcon />}
              />
            </HStack>
          ) : (
            <Flex
              onDrop={formik.values.reseller ? handleFileDrop : undefined}
              onDragOver={formik.values.reseller ? handleDragOver : undefined}
              height="78px"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              border="1px dashed #4285F4"
              borderRadius="2px"
              p={'16px'}
            >
              <Text>
                Drag and Drop your files here or{' '}
                <Text as="label" variant="outline" color={'#4285F4'} textDecoration={'underline'} cursor="pointer">
                  Choose File
                  <Input
                    type="file"
                    id="file"
                    accept=".pdf"
                    display="none"
                    onChange={e => {
                      if (e.target.files && e.target.files.length > 0) {
                        handleFileSelection(e.target.files[0]);
                      } else {
                        toast({
                          status: 'error',
                          title: 'Error',
                          description: 'Error selecting file ',
                        });
                      }
                    }}
                    onClick={(e: any) => {
                      e.target.value = '';
                    }}
                  />
                </Text>
              </Text>
              <Text fontSize={'12px'} color={'#5F647B'}>
                Max file size: 10MB. Supported type: PDF
              </Text>
            </Flex>
          )}
        </Stack>
      </form>
    </ModalPopup>
  );
};
