import { Checkbox, CheckboxGroup, HStack, IconButton, Stack } from '@chakra-ui/react';
import Button from 'component-library/components/ButtonTmp/button';
import Text from 'component-library/components/Text';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { ColumnIcon, RemoveIcon } from 'components/icons';
import { useState } from 'react';
import { capitalizeFirstLetter } from 'utils';

type ColumnFilterProps = {
  headers: string[];
  onToggleColumn: (columns: string[]) => void;
  visibleColumns: string[];
  dropdownTopOffset?: number;
};

/**
 * ColumnFilter component allows users to toggle the visibility of table columns.
 * It accepts the headers of all table as props and displays checkbox with those headers in dropdown.
 * visibleColumns contain header names that are to be displayed in table
 * The dropdown is positioned relative to the filter button, position can be changed by dropdownTopOffset.
 */
const ColumnFilter = ({ headers, onToggleColumn, visibleColumns, dropdownTopOffset = 50 }: ColumnFilterProps) => {
  const [showFilter, setShowFilter] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(visibleColumns);

  const handleCheckboxChange = (column: string) => {
    setSelectedColumns(prev => (prev.includes(column) ? prev.filter(col => col !== column) : [...prev, column]));
  };

  const handleApply = () => {
    onToggleColumn(selectedColumns);
    setShowFilter(false);
  };

  const handleCancel = () => {
    setSelectedColumns(visibleColumns);
    setShowFilter(false);
  };

  return (
    <>
      <Tooltip shouldWrapChildren label={'Customize Columns'}>
        <IconButton
          variant={'transparent-with-icon'}
          icon={<ColumnIcon />}
          aria-label="Filter columns"
          onClick={() => setShowFilter(prev => !prev)}
        />
      </Tooltip>
      {showFilter && (
        <Stack
          position="absolute"
          top={{ base: `${dropdownTopOffset}px`, md: `${dropdownTopOffset}px`, sm: '176px' }}
          right={{ sm: '32px' }}
          bg={'#fff'}
          width={'298px'}
          height={'auto'}
          padding={'16px'}
          borderRadius={'2px'}
          zIndex={'1'}
          boxShadow="-3px -2px 6px -3px rgba(23, 24, 24, 0.08), 0px 8px 20px -4px rgba(23, 24, 24, 0.12)"
        >
          <HStack justifyContent={'space-between'}>
            <Text fontSize={'18px'} fontWeight="medium">
              Customize Columns
            </Text>

            <IconButton
              variant={'transparent-with-icon'}
              icon={<RemoveIcon />}
              aria-label="Close"
              onClick={() => setShowFilter(false)}
            />
          </HStack>
          <Stack gap="16px" mb={'16px'}>
            <CheckboxGroup>
              {headers.map(column => (
                <Checkbox
                  key={column}
                  isChecked={selectedColumns.includes(column)}
                  onChange={() => handleCheckboxChange(column)}
                  gap="8px"
                >
                  <Text>{capitalizeFirstLetter(column)}</Text>
                </Checkbox>
              ))}
            </CheckboxGroup>
          </Stack>
          <HStack direction="row" justifyContent={'space-around'} spacing="18px">
            <Button variant="outline" color="secondary" width={'132px'} height={'32px'} onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="solid" color="primary" width={'132px'} height={'32px'} onClick={handleApply}>
              Apply
            </Button>
          </HStack>
        </Stack>
      )}
    </>
  );
};

export default ColumnFilter;
