import { Stack, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getPhysicalNexus, PHYSICAL_NEXUS_KEY } from 'apis/physical-nexus-apis';
import { getTransactions, TRANSACTION_STATE_KEY } from 'apis/transactions';
import { TableSkeleton } from 'component-library/components/Skeleton/table-skeleton';
import { PaginationButtons } from 'components/pagination/pagination';
import { useOrg } from 'hooks/useOrg';
import useTable from 'hooks/useTable';
import { useState } from 'react';
import { PhysicalNexusInstance } from 'types/shared-types';

import { PhysicalNexusModal } from './components/physical-nexus-modal';
import { PhysicalNexusTable } from './components/physical-nexus-table';

const Presence = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { orgId } = useOrg();
  const { activePage, changeActivePage, pageSize, changePageSize } = useTable();
  const [physicalNexus, setPhysicalNexus] = useState<PhysicalNexusInstance>();

  const { isLoading, data, isError } = useQuery({
    queryKey: [PHYSICAL_NEXUS_KEY, orgId, activePage, pageSize],
    queryFn: async () => {
      const { data } = await getPhysicalNexus(orgId, activePage, pageSize);
      return data;
    },
  });

  const { isPending, data: isTransactionAvailable = false } = useQuery({
    queryKey: [TRANSACTION_STATE_KEY, orgId, activePage, pageSize],
    queryFn: async () => {
      const res = await getTransactions(orgId, activePage, pageSize);
      return res.data?.items?.length > 0;
    },
  });

  if (isLoading || isPending) {
    return <TableSkeleton items={pageSize} />;
  }

  if (isError && !data) {
    return null;
  }

  const handleOnEdit = (physicalNexus: PhysicalNexusInstance) => {
    setPhysicalNexus(physicalNexus);
    onOpen();
  };

  return (
    <>
      <Stack height={'100%'}>
        <PhysicalNexusTable data={data?.items} isTransactionAvailable={isTransactionAvailable} onEdit={handleOnEdit} />
        {data.items.length !== 0 && (
          <PaginationButtons
            size={pageSize}
            currentPage={activePage}
            totalPages={data?.pages}
            onPageClick={changeActivePage}
            onSizeChange={changePageSize}
          />
        )}
      </Stack>
      {isOpen && <PhysicalNexusModal isOpen={isOpen} onClose={onClose} physicalNexus={physicalNexus} />}
    </>
  );
};

export default Presence;
