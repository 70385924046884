import { IconButton, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CUSTOMERS_STATE_KEY } from 'apis/customers';
import { downloadResellerCertificate, updateExemption } from 'apis/exemption-apis';
import { EXEMPTIONS_STATE_KEY } from 'apis/exemptions';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { EditExemptionsForm } from 'pages/Exemptions/components/editExemptionForm';
import { MdMoreVert } from 'react-icons/md';

import { ArchiveExemptionForm } from './archiveExemptionModal';

type ExemptionActionProps = {
  exemption: any;
};
export const ExemptionAction = ({ exemption }: ExemptionActionProps) => {
  const { orgId } = useOrg();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const queryClient = useQueryClient();

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const { isOpen: isArchiveModalOpen, onOpen: onArchiveModalOpen, onClose: onArchiveModalClose } = useDisclosure();

  const {
    isOpen: isEditExemptionFormOpen,
    onOpen: onEditExemptionFormOpen,
    onClose: onEditExemptionFormClose,
  } = useDisclosure();

  const updateExemptionStatus = useMutation({
    mutationFn: (status: string) => {
      const payload = { ...exemption, status: status };
      return updateExemption(orgId, exemption.id, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CUSTOMERS_STATE_KEY, orgId] });
      queryClient.invalidateQueries({ queryKey: [EXEMPTIONS_STATE_KEY, orgId] });
      handleSuccessNotification('Exemption status updated successfully');
    },
  });

  const handleEditExemptionFormOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    onEditExemptionFormOpen();
  };

  const handleActivate = (event: React.MouseEvent) => {
    event.stopPropagation();
    updateExemptionStatus.mutate('ACTIVE');
  };

  const handleDeactivate = (event: React.MouseEvent) => {
    event.stopPropagation();
    updateExemptionStatus.mutate('DEACTIVATED');
  };

  const handleArchive = (event: React.MouseEvent) => {
    event.stopPropagation();
    onArchiveModalOpen();
  };

  const handleDownload = (attachment_id: any) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (attachment_id) {
      downloadResellerCertificate(attachment_id, orgId)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'reseller_certificate.pdf');
          document.body.appendChild(link);
          link.click();
          handleSuccessNotification('File downloaded successfully');
        })
        .catch(error => {
          handleFailNotification(error);
        });
    }
  };
  return (
    <Menu placement="top-start">
      <MenuButton
        onClick={handleClick}
        as={IconButton}
        aria-label="Options"
        icon={<MdMoreVert size={'24px'} />}
        variant="unstyled"
        height={'24px'}
      >
        Actions
      </MenuButton>
      <MenuList>
        <MenuItem onClick={handleEditExemptionFormOpen}>Edit</MenuItem>
        {isEditExemptionFormOpen && (
          <EditExemptionsForm
            isOpen={isEditExemptionFormOpen}
            onClose={onEditExemptionFormClose}
            exemption={exemption}
          />
        )}
        <MenuItem isDisabled={exemption.attachment_id == null} onClick={handleDownload(exemption.attachment_id)}>
          Download Document
        </MenuItem>
        {exemption.status == 'DEACTIVATED' ? (
          <>
            <MenuItem onClick={handleActivate}>Activate</MenuItem>
            <MenuItem onClick={handleArchive}>Archive</MenuItem>
          </>
        ) : (
          <MenuItem onClick={handleDeactivate}>Deactivate</MenuItem>
        )}
      </MenuList>
      {isArchiveModalOpen && (
        <ArchiveExemptionForm
          exemption={exemption}
          orgId={orgId}
          isOpen={isArchiveModalOpen}
          onClose={onArchiveModalClose}
        />
      )}
    </Menu>
  );
};
