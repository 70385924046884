import { ApideckVault } from '@apideck/vault-js';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CONNECTIONS_STATE_KEY,
  createApiDeckConnection,
  deleteApiDeckConnection,
  updateApiDeckConnections,
} from 'apis/connections';
import { getOrgDetails, ORG_DETAILS_STATE_KEY } from 'apis/organizations-apis';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BusinessAddressPrompt } from '../../update-address-prompt';
import { ItemCard } from '../item-card';
import { BigcommerceIcon } from './icons/bigcommerce';

export default function BigCommerceTab() {
  const { orgId } = useOrg();
  const { track } = useTracking();
  const { handleSuccessNotification } = useHandleNotification();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [bigcommerceData, setBigcommerceData] = useState({
    consumer_id: '',
  });
  const [isBusinessAddressPromptOpen, setIsBusinessAddressPromptOpen] = useState(false);
  const [isBigCommerceLoading, setIsBigCommerceLoading] = useState(false);
  const { data } = useQuery({
    queryKey: [ORG_DETAILS_STATE_KEY, orgId],
    queryFn: async () => {
      const { data } = await getOrgDetails(orgId);
      return data;
    },
  });
  const isAddressMissing = data ? !data.company_country_code || !data.company_postal_code : true;

  const bigCommerceMutation = useMutation({
    mutationFn: () => {
      return updateApiDeckConnections(bigcommerceData.consumer_id, orgId, 'ACTIVE');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CONNECTIONS_STATE_KEY] });
      handleSuccessNotification('BigCommerce Connected SuccessFully.');
      navigate('/source');
    },
  });

  const onBigCommerceAuthenticated = async (connection: any) => {
    // Reference on Apideck's connection state:
    // https://developers.apideck.com/apis/vault/reference#section/Unify-and-Proxy-integration

    // callable: the connection is authorized and ready for next steps
    if (connection.state === 'callable') {
      bigCommerceMutation.mutate();
      track('connects integration', { integration: 'bigcommerce' });
    }

    // added: the connection was added but not yet authorized
    if (connection.state === 'added') {
      updateApiDeckConnections(bigcommerceData.consumer_id, orgId, 'INACTIVE');
    }
  };

  const onApideckVaultClose = (connection_id: string) => {
    // If Apideck Vault was closed before a connection was established,
    // we will remove the connection from db
    deleteApiDeckConnection(connection_id, orgId);
  };

  const handleApiDeckIntegration = async (serviceId: string) => {
    try {
      setIsBigCommerceLoading(true);
      const response = await createApiDeckConnection(orgId, serviceId, {});
      const responseData = response.data;
      if (responseData) {
        const sessionToken = responseData.token;
        const connection_id = responseData.connection_id;
        setBigcommerceData({ ...bigcommerceData, consumer_id: connection_id });

        ApideckVault.open({
          token: sessionToken,
          unifiedApi: 'ecommerce',
          serviceId: serviceId,
          onConnectionChange: onBigCommerceAuthenticated,
          onClose: () => onApideckVaultClose(connection_id),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <ItemCard
        title="BigCommerce"
        description=" Enterprise ecommerce, simplified"
        icon={<BigcommerceIcon />}
        onConnect={() =>
          isAddressMissing ? setIsBusinessAddressPromptOpen(true) : handleApiDeckIntegration('bigcommerce')
        }
        btnProps={{ isLoading: isBigCommerceLoading }}
      />
      <BusinessAddressPrompt
        isOpen={isBusinessAddressPromptOpen}
        onClose={() => setIsBusinessAddressPromptOpen(false)}
        onSuccess={() => handleApiDeckIntegration('bigcommerce')}
      />
    </>
  );
}
