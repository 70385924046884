import { Flex, FormControl, HStack, Input, SimpleGrid, Skeleton, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { NEXUS_STATE_KEY } from 'apis/dashboard-apis';
import { getNexusById, registerNexus } from 'apis/nexus';
import {
  getAllEmailGroups,
  getAutoFileAutoRegister,
  ORG_DETAILS_STATE_KEY,
  putAutoFileAutoRegister,
} from 'apis/organizations-apis';
import { REGISTRATION_STATE_KEY } from 'apis/registration-apis';
import { usePaywall } from 'app/acl/paywall';
import Button from 'component-library/components/ButtonTmp/button';
import FormLabel from 'component-library/components/FormLabel';
import MenuDropDown from 'component-library/components/Menu/MenuDropDown';
import MenuItem from 'component-library/components/Menu/MenuItem';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import Select from 'component-library/components/Select/Select';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { AutoFileRegisterToggle } from 'pages/Settings/components/org-settings/auto-file-register-toggle';
import { useEffect, useState } from 'react';
import { MdMoreVert } from 'react-icons/md';
import { AutoFileAutoRegisterUpdateRequest, RegistrationInstance, RegistrationStatus } from 'types/shared-types';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  country_code: Yup.string().required('Country is required'),
  state_code: Yup.string().required('State is required'),
  registration_email: Yup.string().email('Invalid email address').required('Email is required'),
});

const RequestRegistrationFormModal = () => {
  const { isOpen, onOpen, onClose: closeModal } = useDisclosure();
  const { orgId, isTest } = useOrg();
  const { isPaidUser } = usePaywall();
  const queryClient = useQueryClient();
  const { handleSuccessNotification } = useHandleNotification();
  const [nexusId] = useQueryParam('nexusId');
  const [requestRegistration, setSearchParams] = useQueryParam('requestRegistration', '');
  const [edit, setEdit] = useState(false);

  const { isLoading, data, refetch, isError } = useQuery({
    enabled: false,
    queryKey: [NEXUS_STATE_KEY, nexusId],
    queryFn: async () => {
      const res = await getNexusById(nexusId, orgId);
      if (res?.data) {
        formik.setFieldValue('state_code', res?.data.state_code);
      }
      return res?.data || {};
    },
  });

  const { data: virtualMail, isLoading: isVirtualMailLoading } = useQuery({
    queryKey: ['VIRTUAL-EMAIL', orgId],
    queryFn: async () => {
      const res = await getAllEmailGroups(orgId);
      return res?.data;
    },
    enabled: !isTest,
  });

  const { isLoading: isOrgDetailsLoading, data: orgDetails } = useQuery({
    queryKey: [ORG_DETAILS_STATE_KEY, orgId],
    queryFn: async () => {
      const { data } = await getAutoFileAutoRegister(orgId);
      return data || {};
    },
    enabled: !isTest,
  });

  const formik = useFormik({
    initialValues: {
      country_code: 'US',
      state_code: data?.state_code,
      registration_email: virtualMail ? virtualMail[0] : data?.registration_email,
      status: RegistrationStatus.REGISTERED,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      mutation.mutate(values);
    },
  });

  const autoRegisterMutation = useMutation({
    mutationFn: async (payload: AutoFileAutoRegisterUpdateRequest) => {
      return putAutoFileAutoRegister(orgId, payload);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [ORG_DETAILS_STATE_KEY] });
    },
  });

  const autoRegisterformik = useFormik({
    initialValues: {
      auto_register: orgDetails?.auto_register || false,
      auto_file: orgDetails?.auto_file || false,
    },
    onSubmit: async values => {
      await autoRegisterMutation.mutateAsync(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!isOpen && requestRegistration && nexusId) {
      onOpen();
      refetch();
    }
  }, [requestRegistration]);

  useEffect(() => {
    if (isError) {
      onClose();
    }
  }, [isError]);

  const onClose = () => {
    setSearchParams({});
    closeModal();
    setEdit(false);
  };

  const mutation = useMutation({
    mutationFn: (payload: Partial<RegistrationInstance>) => {
      return registerNexus(nexusId, orgId, payload);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [REGISTRATION_STATE_KEY] });
      queryClient.invalidateQueries({ queryKey: [NEXUS_STATE_KEY] });
      if (autoRegisterformik.isValid && autoRegisterformik.values.auto_register) {
        await autoRegisterformik.submitForm();
      }
      onClose();
      handleSuccessNotification('Registration updated successfully.');
    },
  });

  return (
    isOpen && (
      <ModalPopup
        closeOnOverlayClick={false}
        size={'lg'}
        isOpen={isOpen}
        onClose={onClose}
        header={'Request Registration'}
        footer={
          <Flex gap={2}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={mutation.isPending}
              variant={'solid'}
              color={'primary'}
              width={'90px'}
              onClick={() => {
                if (data?.is_manual) {
                  formik.setValues({ ...formik.values, status: RegistrationStatus.REGISTERED });
                } else {
                  formik.setValues({ ...formik.values, status: RegistrationStatus.PROCESSING });
                }
                if (formik.isValid) {
                  formik.handleSubmit();
                } else {
                  formik.setTouched({
                    registration_email: true,
                  });
                }
              }}
            >
              Save
            </Button>
          </Flex>
        }
      >
        {isLoading || isVirtualMailLoading || isOrgDetailsLoading ? (
          <VStack spacing={4}>
            {Array.from({ length: 3 }, (_, index) => (
              <Skeleton key={index} height="30px" width="full" />
            ))}
          </VStack>
        ) : (
          <SimpleGrid rowGap={4}>
            <SimpleGrid columns={2} gap={4}>
              <FormControl>
                <FormLabel htmlFor="country_code">Country</FormLabel>
                <Input
                  disabled
                  isReadOnly
                  id="country_code"
                  type="text"
                  name="country_code"
                  value={formik.values.country_code}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="state_code">State</FormLabel>
                <Select isDisabled id="state_code" name="state_code" value={formik.values.state_code}>
                  <option>{data?.state_name}</option>
                </Select>
                {formik.errors.state_code && formik.touched.state_code && (
                  <Text color={'#E53E3E'}>{formik?.errors?.state_code?.toString()}</Text>
                )}
              </FormControl>
            </SimpleGrid>
            <FormControl isRequired>
              <FormLabel htmlFor="registration_email">Registration Email</FormLabel>
              {edit || !formik.values.registration_email ? (
                <Input
                  id="registration_email"
                  type="email"
                  name="registration_email"
                  value={formik.values.registration_email}
                  onChange={formik.handleChange}
                />
              ) : (
                <HStack justifyContent={'space-between'}>
                  <Text>{formik.values.registration_email}</Text>
                  <MenuDropDown
                    trigger={<MdMoreVert size={'20px'} />}
                    itemList={[
                      <MenuItem key="edit" onClick={() => setEdit(true)}>
                        Edit
                      </MenuItem>,
                    ]}
                  />
                </HStack>
              )}

              {formik.errors.registration_email && formik.touched.registration_email && (
                <Text color={'#E53E3E'}>{formik.errors.registration_email.toString()}</Text>
              )}
            </FormControl>
            {isPaidUser && !orgDetails?.auto_register && (
              <AutoFileRegisterToggle
                label="Auto Register"
                fieldName="auto_register"
                formik={autoRegisterformik}
                p={2}
                my={2}
                fontSize={'sm'}
                tooltip="Automatically approve registrations as soon as the nexus
is met. You can toggle this OFF anytime from Settings."
              />
            )}
          </SimpleGrid>
        )}
      </ModalPopup>
    )
  );
};

export const RequestRegistrationForm = () => {
  const { isPaidUser, openPaymentCheckout } = usePaywall();
  const { isTest } = useOrg();
  const [requestRegistration, setSearchParams] = useQueryParam('requestRegistration', '');

  useEffect(() => {
    if (requestRegistration && !isPaidUser && !isTest) {
      openPaymentCheckout({
        onClose: () => {
          setSearchParams({});
        },
      });
      return;
    }
  }, [requestRegistration]);

  return requestRegistration && (isTest || isPaidUser) ? <RequestRegistrationFormModal /> : null;
};
