import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TableFilter, UseTableFilters } from '../types/shared-types';

const FILTER_KEYS = [
  'page',
  'size',
  'status__in',
  'product_category__in',
  'start_date',
  'end_date',
  'date_filed__gte',
  'date_filed__lte',
  'order_by',
  'state_code',
  'country_code',
  'state_name',
  'source__in',
];

export const useTableFilters = (initialFilters: UseTableFilters = { page: 1, size: 25 }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getValidParam = useCallback(
    (key: string, defaultValue: UseTableFilters[keyof UseTableFilters]) => {
      const param = searchParams.get(key);
      if (['page', 'size'].includes(key)) {
        const parsed = param ? parseInt(param, 10) : defaultValue;
        return Number.isNaN(parsed) ? defaultValue : parsed;
      }
      if (Array.isArray(defaultValue)) {
        return param?.split(',') ?? defaultValue.join(',');
      }
      return param ?? defaultValue;
    },
    [searchParams]
  );

  const validFilters = useMemo(() => {
    const filters = FILTER_KEYS.reduce((acc, key) => {
      (acc[key as keyof UseTableFilters] as any) = getValidParam(key, initialFilters[key as keyof UseTableFilters]);
      return acc;
    }, {} as TableFilter);
    return {
      ...filters,
      page: filters.page ?? 1,
      size: filters.size ?? 25,
    };
  }, [getValidParam, initialFilters]);

  const setFilters = useCallback(
    (newFilters: UseTableFilters = {}) => {
      setSearchParams(params => {
        const setPageAndSize = (page: string, size: string) => {
          params.set('page', page);
          params.set('size', size);
        };

        setPageAndSize(newFilters.page?.toString() ?? '1', newFilters.size?.toString() ?? '25');

        Object.entries(newFilters).forEach(([key, value]) => {
          if (key !== 'page' && key !== 'size') {
            if (value && (typeof value === 'string' || (Array.isArray(value) && value.length > 0))) {
              params.set(key, Array.isArray(value) ? value.join(',') : value);
              setPageAndSize('1', '25');
            } else {
              params.delete(key);
            }
          }
        });

        return params;
      });
    },
    [setSearchParams]
  );

  const resetFilters = useCallback(() => {
    setSearchParams(params => {
      Object.keys(validFilters).forEach(key => params.delete(key));
      return params;
    });
  }, [setSearchParams, validFilters]);

  return { ...validFilters, setFilters, resetFilters };
};

export type UseTableFiltersType = ReturnType<typeof useTableFilters>;
