import { TransactionRead } from '_api-client';
import { Flex, HStack, IconButton, Stack, Td, Tr, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { TRANSACTION_STATE_KEY } from 'apis/transaction-api';
import { getTransactions } from 'apis/transactions';
import AppHeader from 'app/app-header';
import { TableSkeleton } from 'component-library/components/Skeleton/table-skeleton';
import Text from 'component-library/components/Text';
import { AIIcon } from 'components/icons';
import { PaginationButtons } from 'components/pagination/pagination';
import KSearchInput from 'components/SearchInput';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { useOrg } from 'hooks/useOrg';
import useTable from 'hooks/useTable';
import { useState } from 'react';
import { AddressInstance, AddressStatus } from 'types/shared-types';
import { toDateShort } from 'utils/dates';
import { formatCurrency } from 'utils/utils';

import AddBlankAddressPopover from './AddBlankAddressPopover';
import AIBlankAddressAddModal from './AIBlankAddressAddModal';

const TABLE_HEAD = ['ID', 'DESCRIPTION', 'DATE', 'AMOUNT', 'CUSTOMER NAME', ''];
const NUMERIC_COL = ['AMOUNT'];

const BlankAddressTransactions = () => {
  const { orgId } = useOrg();
  const { isOpen: isOpenAIModal, onOpen: onOpenAIModal, onClose: onCloseAIModal } = useDisclosure();
  const [query, setQuery] = useState<string>('');
  const order = { order_by: '-date' };

  const filter = {
    address_status__in: AddressStatus.BLANK,
  };
  const { activePage, pageSize, changeActivePage, changePageSize } = useTable({
    initialActivePage: 1,
    initialPageSize: 25,
  });

  const { isPending, data } = useQuery({
    queryKey: [TRANSACTION_STATE_KEY, orgId, activePage, pageSize, filter, order, query],
    queryFn: async () => {
      const res = await getTransactions(orgId, activePage, pageSize, filter, order, query);
      return res.data || {};
    },
  });

  const blankAddressTransactionData: TransactionRead[] = data?.items;
  const isDataEmpty = !blankAddressTransactionData?.length;
  const totalPages = data?.pages;

  if (isPending) {
    return <TableSkeleton items={pageSize} />;
  }

  // handle initial data empty
  if (isDataEmpty && !query) {
    return <TableEmptyState tableName="Blank Address Transactions" />;
  }

  return (
    <Stack>
      <HStack gap={1} justifyContent={'space-between'} alignItems={'center'}>
        <AppHeader />
        <HStack>
          <KSearchInput query={query} setQuery={setQuery} />
          <IconButton
            aria-label={'Revalidate Address using kintsugi intelligence'}
            mr={1}
            variant={'transparent-with-icon'}
            onClick={onOpenAIModal}
          >
            <AIIcon />
          </IconButton>
        </HStack>
      </HStack>

      {isDataEmpty && query ? (
        /* Empty state for search & filter */
        <TableEmptyState tableName="Blank Address Transactions" isSearchApplied={!!query} />
      ) : (
        <KDataTable headers={TABLE_HEAD} numericCols={NUMERIC_COL} showColumnFilter>
          {blankAddressTransactionData?.map((transaction: TransactionRead) => {
            return <BlankAddressTransactionTableRow key={transaction.id} transaction={transaction} />;
          })}
        </KDataTable>
      )}

      {!isDataEmpty && (
        <PaginationButtons
          size={pageSize}
          currentPage={activePage}
          totalPages={totalPages}
          onPageClick={changeActivePage}
          onSizeChange={changePageSize}
        />
      )}
      {isOpenAIModal && <AIBlankAddressAddModal isOpen={isOpenAIModal} onClose={onCloseAIModal} />}
    </Stack>
  );
};

export default BlankAddressTransactions;

const BlankAddressTransactionTableRow = ({ transaction }: { transaction: TransactionRead }) => {
  const { external_id, id, description, date, total_amount, customer } = transaction;

  return (
    <Tr>
      <Td width={'8.75rem'}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{external_id}</Text>
        </Flex>
      </Td>
      <Td width={'24.5rem'}>
        <Text noOfLines={1} maxWidth={'100%'}>
          {description}
        </Text>
      </Td>
      <Td width={'7.5rem'}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{toDateShort(date)}</Text>
        </Flex>
      </Td>

      <Td width={'9.18rem'} isNumeric={true}>
        <Text>{formatCurrency(total_amount)}</Text>
      </Td>

      <Td width={'17.5rem'}>
        <Text noOfLines={1}>{customer.name}</Text>
      </Td>

      <Td width={'8.75rem'}>
        {/* blank address always has only one address bill to */}
        <AddBlankAddressPopover key={id} payload={transaction.addresses[0] as AddressInstance} />
      </Td>
    </Tr>
  );
};
