import { Card, VStack } from '@chakra-ui/react';
import Button, { CustomButtonProps } from 'component-library/components/ButtonTmp/button';
import Text from 'component-library/components/Text';

type ItemCardProps = {
  title: string;
  description: string;
  icon: React.ReactNode;
  onConnect: () => void;
  btnProps?: CustomButtonProps;
};

export const ItemCard = ({ title, description, icon, onConnect, btnProps = {} }: ItemCardProps) => {
  return (
    <Card p={4} gap={4} border={'1px #CFD0D8 solid'} borderRadius={'4px'} justify={'space-between'}>
      {icon}
      <VStack justify={'center'} spacing={0}>
        <Text size={'lg'}>{title}</Text>
        <Text size={'md'} color={'#4B5169'}>
          {description}
        </Text>
      </VStack>
      <Button color={'secondary'} {...btnProps} onClick={onConnect}>
        Connect
      </Button>
    </Card>
  );
};
