import api from 'apis/api';

const getConnections = (orgId: any) => {
  return api.get(`/v1/connections`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      status: 'all',
    },
  });
};

const createChargebee = (orgId: any, site_id: any, payload: any) => {
  return api.put(`/v1/connections/chargebee/${site_id}`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const createStripe = (orgId: any, publishable_key: any, payload: any) => {
  return api.put(`/v1/connections/stripe/${publishable_key}`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const createApiDeckConnection = (orgId: any, serviceId: any, payload: any) => {
  return api.put(`/v1/connections/apideck/${serviceId}`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const createApiDeckSession = (orgId: any, connection_id: string) => {
  return api.get(`/v1/connections/apideck/session/${connection_id}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const updateApiDeckConnections = (consumerId: any, orgId: any, status: string) => {
  const payload = {
    status: status,
  };

  return api.put(`/v1/connections/${consumerId}`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const deleteApiDeckConnection = (connection_id: any, orgId: any) => {
  return api.delete(`/v1/connections/${connection_id}/delete`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const syncConnections = (connection_id: any, orgId: any) => {
  return api.post(`/v1/connections/${connection_id}/sync`, '', {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const refreshNexus = (orgId: string) => {
  return api.post('/v1/nexus/refresh', '', {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

function updateConnection(connection_id: string, orgId: string, payload: any) {
  return api.put(`/v1/connections/${connection_id}`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
}

function archiveTransactionsByConnectionId(connection_id: string, orgId: string) {
  return api.delete<string>(`/v1/connections/${connection_id}/transactions`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
}

const CONNECTIONS_STATE_KEY = 'connections';

export {
  archiveTransactionsByConnectionId,
  CONNECTIONS_STATE_KEY,
  createApiDeckConnection,
  createApiDeckSession,
  createChargebee,
  createStripe,
  deleteApiDeckConnection,
  getConnections,
  refreshNexus,
  syncConnections,
  updateApiDeckConnections,
  updateConnection,
};
