import { Flex, Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { exportReportForFiling } from 'apis/filing-apis';
import { usePaywall } from 'app/acl/paywall';
import Button from 'component-library/components/ButtonTmp/button';
import TableContainer from 'component-library/components/Table/table-container';
import Text from 'component-library/components/Text';
import AlertBanner from 'components/alert/alert';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { FormikProps } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import useAsyncActionLoader from 'hooks/useAsyncActionLoader';
import { useOrg } from 'hooks/useOrg';
import { AutoFileRegisterToggle } from 'pages/Settings/components/org-settings/auto-file-register-toggle';
import React from 'react';
import { MdOutlineFileDownload } from 'react-icons/md';
import { FilingDetailsInstance } from 'types/shared-types';
import { toDateShort } from 'utils/dates';
import { formatCurrency } from 'utils/utils';

import { AttachmentCard } from './attachment-card';

type FormValues = {
  auto_file: boolean;
  auto_register: boolean;
};

const FilingDetails: React.FC<{
  data: FilingDetailsInstance;
  shouldShowSubheader?: boolean;
  formik?: FormikProps<FormValues>;
  shouldShowAutoFile?: boolean;
}> = ({ data, shouldShowSubheader, formik, shouldShowAutoFile }) => {
  const { orgId } = useOrg();
  const { isPaidUser } = usePaywall();
  const { loading, executeAction } = useAsyncActionLoader(exportReportForFiling);
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();

  const {
    id,
    start_date,
    end_date,
    due_date,
    amount,
    amount_calculated,
    amount_adjusted,
    amount_discounts,
    amount_tax_collected,
    amount_fees,
    amount_penalties,
    amount_sales,
    can_file,
    state_name,
    total_tax_liability,
    transaction_count,
    internal_notes,
    attachments,
  } = data || {};

  const handleDownloadClick = async () => {
    try {
      await executeAction(orgId, id || '');
      handleSuccessNotification(
        'The report will be sent to your email address and will arrive in the next 1-2 minutes depending on how much data is in your organization.'
      );
    } catch (error) {
      handleFailNotification(error);
    }
  };

  const isCollectedTaxHigher = Number(amount_tax_collected || 0) > Number(amount_calculated || 0);

  const taxOverviewTableData: { label: string; value: number }[] = [
    { label: isCollectedTaxHigher ? 'Collected Tax' : 'Calculated Tax', value: amount },
    { label: 'Adjustment', value: amount_adjusted },
    { label: 'Fees', value: amount_fees },
    { label: 'Penalties', value: amount_penalties },
    { label: 'Discount', value: amount_discounts },
    { label: 'Total Liability', value: total_tax_liability },
  ];

  if (!data) {
    return <TableEmptyState tableName="Filing Details" />;
  }

  return (
    <Flex direction="column" gap={4}>
      {shouldShowSubheader && (
        <Text>
          Please review the details and submit when you are ready to file {formatCurrency(total_tax_liability)}
        </Text>
      )}
      {!can_file && (
        <AlertBanner
          variant={'warningVariant'}
          message="For this filing period there are unapproved products and invalid addresses, please resolve this before continuing."
        />
      )}
      <Flex flexDirection={'column'} gap={2}>
        <Text size={'lg'} fontWeight={'500'}>
          Period Overview
        </Text>
        <TableContainer>
          <Table variant="unstyled">
            <Tbody>
              <Tr>
                <Td width={'100%'}>
                  <Flex justifyContent={'space-between'}>
                    <Text>Jurisdiction</Text>
                    <Text fontWeight={500}>{state_name}</Text>
                  </Flex>
                </Td>
              </Tr>
              <Tr>
                <Td width={'100%'}>
                  <Flex justifyContent={'space-between'}>
                    <Text>Due Date</Text>
                    <Text fontWeight={500}>{toDateShort(due_date)}</Text>
                  </Flex>
                </Td>
              </Tr>
              <Tr>
                <Td width={'100%'}>
                  <Flex justifyContent={'space-between'}>
                    <Text>Period</Text>
                    <Text fontWeight={500}>
                      {toDateShort(start_date)} - {toDateShort(end_date)}
                    </Text>
                  </Flex>
                </Td>
              </Tr>
              <Tr>
                <Td width={'100%'}>
                  <Flex justifyContent={'space-between'}>
                    <Text>Transaction Count</Text>
                    <Text fontWeight={500}>{transaction_count}</Text>
                  </Flex>
                </Td>
              </Tr>
              <Tr>
                <Td width={'100%'}>
                  <Flex justifyContent={'space-between'}>
                    <Text>Transaction Amount</Text>
                    <Text fontWeight={500}>{formatCurrency(amount_sales)}</Text>
                  </Flex>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>

      <Flex flexDirection={'column'} gap={2}>
        <Text size={'lg'} fontWeight={'500'}>
          Tax Overview
        </Text>
        <TableContainer>
          <Table variant="unstyled">
            <Tbody>
              {taxOverviewTableData?.map(({ label, value }) => (
                <Tr key={label}>
                  <Td width={'100%'}>
                    <Flex justifyContent={'space-between'}>
                      <Text>{label}</Text>
                      <Text fontWeight={500}>{formatCurrency(value || 0)}</Text>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>

      <Flex flexDirection={'column'} gap={2}>
        <Text size={'lg'} fontWeight={'500'}>
          Attachments
        </Text>
        {attachments ? (
          Object.entries(attachments)?.map(([key, attachment]) => (
            <AttachmentCard key={key} attachmentName={attachment} attachmentId={key} />
          ))
        ) : (
          <Text>No attachments found</Text>
        )}
      </Flex>

      <Flex flexDirection={'column'} gap={2}>
        <Text size={'lg'} fontWeight={'500'}>
          Note
        </Text>
        <Text>{internal_notes || 'No notes found'}</Text>
      </Flex>

      <span>
        <Button
          onClick={handleDownloadClick}
          isLoading={loading}
          variant="outline"
          color={'secondary'}
          leftIcon={<MdOutlineFileDownload fontSize={'20px'} />}
        >
          Download Report
        </Button>
      </span>

      {isPaidUser && shouldShowSubheader && shouldShowAutoFile && (
        <AutoFileRegisterToggle
          label="Auto File"
          fieldName="auto_file"
          formik={formik}
          mt={0}
          p={2}
          fontSize={'sm'}
          tooltip="Automatically approve all the filings on the 10th of the month.
You can toggle this OFF anytime from Settings."
        />
      )}
    </Flex>
  );
};

export default FilingDetails;
