import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import MenuDropDown from 'component-library/components/Menu/MenuDropDown';
import MenuItem from 'component-library/components/Menu/MenuItem';
import TableContainer from 'component-library/components/Table/table-container';
import KSTooltip from 'component-library/components/Tooltiptmp/tooltip';
import { USStatesByCode } from 'constants/app-constants';
import { useState } from 'react';
import { MdMoreVert } from 'react-icons/md';
import { PhysicalNexusInstance } from 'types/shared-types';
import { toDateShort } from 'utils/dates';

import { RemovePhysicalNexus } from './remove-physical-nexus-modal';

type PhysicalNexusTableProps = {
  data: PhysicalNexusInstance[];
  isTransactionAvailable: boolean;
  onEdit: (physicalNexus: PhysicalNexusInstance) => void;
};

export const PhysicalNexusTable = ({ data, isTransactionAvailable, onEdit }: PhysicalNexusTableProps) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const isDataEmpty = !data || data.length === 0;

  return (
    <>
      {isDataEmpty ? (
        <Flex h={'100%'} justifyContent={'center'} alignItems={'center'}>
          <Text fontSize={'22px'} fontWeight={500}>
            Looks like there are no presence added.
          </Text>
        </Flex>
      ) : (
        <TableContainer>
          <Table variant="unstyled" color={textColor}>
            <Thead>
              <Tr>
                {['Jurisdiction', 'Start Date', 'End Date'].map((caption: string, idx: number) => {
                  return <Th key={idx}>{caption}</Th>;
                })}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((row: PhysicalNexusInstance) => {
                return (
                  <TableRow
                    key={row.state_code}
                    physicalNexus={row}
                    isTransactionAvailable={isTransactionAvailable}
                    onEdit={onEdit}
                  />
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

type TableRowProps = {
  physicalNexus: PhysicalNexusInstance;
  isTransactionAvailable: boolean;
  onEdit: (physicalNexus: PhysicalNexusInstance) => void;
};

const TableRow = ({ physicalNexus, isTransactionAvailable, onEdit }: TableRowProps) => {
  const [confirmRemovePopup, setConfirmRemovePopup] = useState<{ id: string; name: string } | null>(null);
  const stateName = USStatesByCode[physicalNexus.state_code]?.label ?? physicalNexus.state_code;
  const isPresenceNonEditable = isTransactionAvailable && !!physicalNexus.end_date;

  return (
    <>
      <Tr>
        <Td width={{ sm: '100px' }} pl="25px">
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{stateName}</Text>
          </Flex>
        </Td>
        <Td width={{ sm: '100px' }} pl="0px">
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <Text>{toDateShort(physicalNexus.start_date)}</Text>
          </Flex>
        </Td>
        <Td width={{ sm: '100px' }} pl="0px">
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <Text>{toDateShort(physicalNexus.end_date)}</Text>
            <MenuDropDown
              trigger={<MdMoreVert size={'20px'} />}
              itemList={[
                <KSTooltip
                  key="edit-tooltip"
                  label={isPresenceNonEditable ? 'Please contact support to edit presence' : ''}
                >
                  <MenuItem
                    key="edit-menu-item"
                    color={'secondary.500'}
                    isDisabled={isPresenceNonEditable}
                    onClick={() => onEdit(physicalNexus)}
                  >
                    Edit
                  </MenuItem>
                </KSTooltip>,
                <KSTooltip
                  key="remove-tooltip"
                  label={isTransactionAvailable ? 'Please contact support to remove presence' : ''}
                >
                  <MenuItem
                    key="remove-menu-item"
                    isDisabled={isTransactionAvailable}
                    onClick={() => {
                      setConfirmRemovePopup({ id: physicalNexus.id, name: physicalNexus.state_code });
                    }}
                    color={'red.500'}
                  >
                    Remove
                  </MenuItem>
                </KSTooltip>,
              ]}
            />
          </Flex>
        </Td>
      </Tr>
      {confirmRemovePopup?.id && (
        <RemovePhysicalNexus
          physicalNexusId={confirmRemovePopup.id}
          stateName={confirmRemovePopup.name}
          onClose={() => {
            setConfirmRemovePopup(null);
          }}
        />
      )}
    </>
  );
};
